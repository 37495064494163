/*
 *
 * ResetPage constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/ResetPage/DEFAULT_ACTION',
  CLOSE_RESET_MODAL = 'app/ResetPage/CLOSE_RESET_MODAL',
  RESET_SUBMIT = 'app/ResetPage/RESET_SUBMIT',
  RESET_REQUEST = 'app/ResetPage/RESET_REQUEST',
  RESET_SUCCESS = 'app/ResetPage/RESET_SUCCESS',
  RESET_FAILURE = 'app/ResetPage/RESET_FAILURE',
}

export default ActionTypes;
