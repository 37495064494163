/*
 *
 * ResetPage reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  isOpenResetModal: false,
};

function resetPageReducer(state: ContainerState = initialState, action: ContainerActions) {
  switch (action.type) {
    case ActionTypes.RESET_SUCCESS:
      return {
        ...state,
        isOpenResetModal: true,
      };
    case ActionTypes.CLOSE_RESET_MODAL:
      return {
        ...state,
        isOpenResetModal: false,
      };
    default:
      return state;
  }
}

export default resetPageReducer;
