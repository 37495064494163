import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the resetPage state domain
 */

const selectResetPageDomain = (state: ApplicationRootState) => {
  return state ? state.resetPage : initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by ResetPage
 */

const selectResetPage = () =>
  createSelector(selectResetPageDomain, substate => {
    return substate;
  });

export default selectResetPage;
export { selectResetPageDomain };
