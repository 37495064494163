import * as qs from 'utils/queryString';
import { take, call, select, put, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { message } from 'antd';
import { makeSelectLocation } from 'containers/MainLayout/selectors';
import { reset as resetActions } from './actions';
import ActionTypes from './constants';
import { resetPassword } from 'utils/apollo';

function* resetFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.RESET_SUBMIT);
    yield put(resetActions.request());
    const location = yield select(makeSelectLocation());
    const search = qs.parse(location.search);
    const token = search.token;
    const key = search.key;
    const response = yield call(resetPassword, {
      password: payload.password,
      key: key,
      token: token,
    });
    if (!response.errors) {
      // @ts-ignore
      yield put(resetActions.success());
    } else {
      yield put(resetActions.failure(response.errors));
      response.errors.map((e: any) => message.error(e.message));
    }
  }
}

function* closeModalFlow() {
  while (true) {
    yield take(ActionTypes.CLOSE_RESET_MODAL);
    yield put(push('/signin'));
  }
}

// Individual exports for testing
export default function* resetPageSaga() {
  // See example in containers/HomePage/saga.js
  yield fork(resetFlow);
  yield fork(closeModalFlow);
}
