/*
 *
 * ResetPage actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const closeResetModal = () => action(ActionTypes.CLOSE_RESET_MODAL);

export const resetSubmit = (values: any) => action(ActionTypes.RESET_SUBMIT, values);
export const reset = createAsyncAction(ActionTypes.RESET_REQUEST, ActionTypes.RESET_SUCCESS, ActionTypes.RESET_FAILURE)<
  void,
  any,
  Error
>();
