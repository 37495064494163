import * as React from 'react';

import { LockOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';

import { Input } from 'antd';
import * as Sentry from '@sentry/browser';

import Button from '../Button/Button';
import { FormattedMessage } from 'react-intl';
import PageHeader from '../PageHeader/PageHeader';
import media from 'utils/mediaStyle';
import messages from 'containers/ResetPage/messages';
import styled from 'styled-components';
import topBg from './top-bg.png';
import utilsMessages from 'utils/messages';

const Container = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 8pt;
  flex: 1;
  max-width: 720px;
  background-image: url(${topBg});
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 8pt;
  ${media.md`
    padding: 30pt 90pt;
  `};
  p {
    line-height: normal;
  }
`;

const Password = styled(Input.Password)<any>``;

interface ResetFormProps {
  form: any;
  onSubmit: (data: any) => void;
}

class ResetForm extends React.Component<ResetFormProps> {
  public handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form
      .validateFields((err: any, values: any) => {
        if (!err) {
          this.props.onSubmit(values);
        }
      })
      .catch((error) => Sentry.captureException(error, { level: Sentry.Severity.Warning }));
  };

  public checkConfirmPassword = (form: any) => (rule: any, value: any, callback: any) => {
    const password = form.getFieldValue('password');
    const confirmPassword = form.getFieldValue('confirmPassword');
    if (!confirmPassword || !password) {
      callback();
      return;
    }
    if (password !== confirmPassword) {
      callback('Your confirm password is not correct!');
      return;
    }
    const errors = form.getFieldsError(['password', 'confirmPassword']);
    if (errors.password || errors.confirmPassword) {
      form.validateFields(['password', 'confirmPassword'], { force: true });
    }

    callback();
  };

  public render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Container>
        <PageHeader size="large">
          <FormattedMessage {...messages.header} />
        </PageHeader>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Form.Item colon={false} required={false} label="Password">
            {getFieldDecorator('password', {
              rules: [
                { required: true, message: 'Please input your password!' },
                {
                  validator: this.checkConfirmPassword(this.props.form),
                },
              ],
            })(
              <Password
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Password"
                autoComplete="new-password"
              />,
            )}
          </Form.Item>
          <Form.Item colon={false} required={false} label="Confirm Password">
            {getFieldDecorator('confirmPassword', {
              rules: [
                {
                  required: true,
                  message: 'Please input your confirm password!',
                },
                {
                  validator: this.checkConfirmPassword(this.props.form),
                },
              ],
            })(
              <Password
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Confirm Password"
                autoComplete="off"
              />,
            )}
          </Form.Item>
          <Form.Item style={{ marginTop: '12px' }}>
            <Button block type="primary" htmlType="submit">
              <FormattedMessage {...utilsMessages.reset} />
            </Button>
          </Form.Item>
        </Form>
      </Container>
    );
  }
}

export default Form.create<ResetFormProps>()(ResetForm);
