/**
 *
 * ResetPage
 *
 */

import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import translations from 'translations';
import { connect } from 'react-redux';
// import { FormattedMessage } from "react-intl";
import { createStructuredSelector } from 'reselect';
import { compose, Dispatch } from 'redux';

import ResetForm from 'components/ResetForm/ResetForm';
import Modal from 'components/Modal/Modal';
import PromptMessage from 'components/PromptMessage/PromptMessage';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import selectResetPage from './selectors';
import reducer from './reducer';
import { closeResetModal, resetSubmit } from './actions';
import saga from './saga';
import BlockIcon from './Block.svg';

import messages from './messages';
import utilsMessages from 'utils/messages';

import { RootState } from './types';
import { FormattedMessage } from 'react-intl';

interface OwnProps {
  resetpage: any;
}

interface StateProps {}

interface DispatchProps {
  onSubmit: (data: any) => void;
  onCloseSentModal: () => void;
}

type Props = StateProps & DispatchProps & OwnProps;

export class ResetPage extends React.PureComponent<Props> {
  public render() {
    const { isOpenResetModal } = this.props.resetpage;
    return (
      <Fragment>
        <Helmet>
          <title>{`${translations(messages.header)} | Nhà Cung Cấp Kamereo`}</title>
          <meta name="description" content={`${translations(messages.header)}`} />
        </Helmet>
        <ResetForm onSubmit={this.props.onSubmit} />
        <Modal
          isOpen={isOpenResetModal}
          submitLabel={<FormattedMessage {...utilsMessages.signin} />}
          onSubmit={this.props.onCloseSentModal}
        >
          <PromptMessage
            icon={BlockIcon}
            message={[
              <FormattedMessage {...utilsMessages.done} key="done" />,
              <FormattedMessage {...messages.yourPasswordIsReset} key="reset" />,
            ]}
          />
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  resetpage: selectResetPage(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    onSubmit: (data: any) => dispatch(resetSubmit(data)),
    onCloseSentModal: () => dispatch(closeResetModal()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<OwnProps>({
  key: 'resetPage',
  reducer: reducer,
});
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<OwnProps>({ key: 'resetPage', saga: saga });

export default compose(withReducer, withSaga, withConnect)(ResetPage);
